/*
 *  Komponentenweite Textkonstanten.
 */
export const Messages = Object.freeze({
  ALLE_STUDIENANGEBOTE_ANZEIGEN: 'Alle Studienangebote zu den gewählten Studienfeldern anzeigen',
  EINLEITENDER_TEXT:
    'Eine Unterstützung bei der Wahl eines passenden Studienganges' +
    ' bietet die folgende Übersicht an Studienfeldern. Sie ermöglichen die Recherche nach Studienmöglichkeiten,' +
    ' bieten Studieninformationen und eine direkte Suche nach konkreten Studienangeboten.',
  GEWAEHLTE_STUDIENFELDER: 'Gewählte Studienfelder:',
  WEITERE_STUDIENFELDER: 'Weitere Studienfelder',
  ZUSAETZLICHE_STUDIENFELDER: 'Zusätzliche Studienfelder',
  GEWAEHLTE_STUDIENFELDER_ANZEIGEN: 'gewählte Studienfelder anzeigen',
  GEWAEHLTE_STUDIENFELDER_AUSBLENDEN: 'gewählte Studienfelder ausblenden',
  WEITERE_STUDIENBEREICHE: 'Weitere Studienbereiche',
  ZUSAETZLICHE_STUDIENBEREICHE: 'Zusätzliche Studienbereiche',
  GEWAEHLTE_STUDIENBEREICHE_ANZEIGEN: 'gewählte Studienbereiche anzeigen',
  GEWAEHLTE_STUDIENBEREICHE_AUSBLENDEN: 'gewählte Studienbereiche ausblenden',
  KEINE_VORSCHLAEGE_GEFUNDEN: 'Zur Sucheingabe konnten keine Studienangebote gefunden werden.',
  TAG_SUCHWORT_TOOLTIP_REMOVE: 'Suchwort %name% zurücksetzen',
  TAG_STUDIENFELD_TOOLTIP_CLICK: 'Nähere Informationen zum Studienfeld %name% aufrufen',
  TAG_STUDIENFELD_TOOLTIP_REMOVE: '%name% zurücksetzen',
  TAG_STUDIENFACH_TOOLTIP_CLICK: 'Nähere Informationen zum Studienfach %name% aufrufen',
  PASSENDE_STUDIENFAECHER_SUCHEN: 'Passende Studienfächer suchen',
  STUDIENANGEBOTE_ANZEIGEN: 'Studienangebote anzeigen',
  UEBERSCHRIFT: 'Studienfeld wählen',
  WEITERE_INFO_AUSBLENDEN: 'Weitere Informationen zu %name% ausblenden',
  WEITERE_INFO_EINBLENDEN: 'Weitere Informationen zu %name% einblenden',
  ZU_DEN_STUDIENANGEBOTEN: 'Zu den Studienangeboten',
  ZU_DEN_STUDIENANGEBOTEN_IM_BEREICH: 'Suchen nach ',
  STUDIENFELD_AENDERN: 'Studienfeld ändern',
  STUDIENFACH_EINGABEFELD_INFO: 'Was? (z.B. Studienfächer oder Systematiksuchworte…)',
  STUDIENFACH_EINGABEFELD_LABEL: 'Studienfächer',
  STUDIENFELD_EINGABEFELD_INFO: 'Was? (z.B. Studienfelder...)',
  STUDIENFELD_EINGABEFELD_LABEL: 'Studienfelder',
  GEWAEHLTER_STUDIENBEREICH: 'Gewählter Studienbereich',
  GEWAEHLTE_STUDIENBEREICHE: 'Gewählte Studienbereiche',
  STUDIENBEREICHE_ALLE_LOESCHEN_TOOLTIP: 'Alle gewählten Studienbereiche entfernen',
  ORT_EINGABEPARAMETER_FEHLERHAFT: 'Geben Sie eine gültige Ortsbezeichnung oder PLZ ein',
  ORT_EINGABEPARAMETER_PLZ: 'Bitte geben Sie eine 5 stellige PLZ an',
  EINGABE_ZU_LANG: 'Suchbegriff zu lang',
  ORT_AUSWAHL_MAX_ORTE: 'Max. 3 Orte',
  ORT_EINGABEFELD_INFO: 'Wo? (z.B. Ort oder PLZ...)',
  SUCHWORTE_EINGABEPARAMETER_FEHLERHAFT: 'Ihre Eingabe enthält ungültige Zeichen.',
  GEWAEHLTER_ORT: 'Gewählte Region',
  GEWAEHLTE_ORTE: 'Gewählte Regionen',
  GEWAEHLTE_ORTE_ALLE_LOESCHEN_TOOLTIP: 'Alle gewählten Regionen entfernen',
  STUDIENBEREICHE_EINGABEPARAMETER_FEHLERHAFT:
    'Studienfach existiert nicht oder enthält ungültige Zeichen',
  STUDIENFELDER_EINGABEPARAMETER_FEHLERHAFT:
    'Studienfeld existiert nicht oder enthält ungültige Zeichen',
  STUDIENFELD_OEFFNEN: 'STUDIENFELD ÖFFNEN',
  STUDIENFELD_OEFFNEN_TOOLTIP:
    'Nähere Informationen zum Studienfeld und zugehörige Studienfächer ansehen',
  STUDIENFELD_OEFFNEN_ARIA_LABEL: 'Studienfeld %name% öffnen',
  FEHLER_AUFGETRETEN: 'Es ist ein Fehler aufgetreten',
  FEHLER_LADEN_DATEN: 'Es konnten aufgrund technischer Probleme keine Daten geladen werden.',
  FEHLER_LADEN_FILTER: 'Es konnten aufgrund technischer Probleme keine Filter geladen werden.',
  FEHLER_LADEN_STUDIENBEREICH: 'Die Studienbereichssuche steht derzeit nicht zur Verfügung',
  FEHLER_LADEN_STUDIENFELDER: 'Die Studienfeldersuche steht derzeit nicht zur Verfügung',
  FEHLER_LADEN_ORT: 'Die Ortssuche steht derzeit nicht zur Verfügung',
  FEHLER_STUDIENFELD_FEHLT:
    'Eingabe Studienfach erforderlich oder über die Entdeckerwelten navigieren.',
  URLPARAM_UK_VALUE_BUNDESWEIT: 'Bundesweit',
  ERNEUT_VERSUCHEN: 'Erneut versuchen',
  ABBRECHEN: 'Abbrechen',
  SCHLIESSEN: 'Schließen',
  KEINE_ERGEBNISSE: 'keine Ergebnisse',
  KEINE_SUCHKRITERIEN_ANGEGEBEN: 'Keine Suchkriterien angegeben.',
  MINDESTENS_STUDIENFACH_ERFORDERLICH: 'Mindestens ein Studienfach erforderlich.',
  KEINE_PASSENDEN_ERGEBNISSE: 'Keine passenden Ergebnisse zu den gewählten Kriterien gefunden.',
  BEREITS_ANGESEHEN_TOOLTIP: 'Bereits angesehen',
  POPUP_UEBERSCHRIFT_OSA: 'OSA',
  POPUP_UEBERSCHRIFT_STUDIENCHECK: 'Studiencheck',
  POPUP_INFO_OSA:
    'Das OSA (Online-Self-Assessment oder auch Online Studienwahl Assistent) ist ein webbasierter Selbsttest,' +
    ' der Studieninteressierte dabei unterstützt, sich im Vorfeld darüber zu informieren, ob das gewünschte Studium den eigenen' +
    ' Erwartungen tatsächlich entspricht und ob die Neigungen sowie Kompetenzen zu den Studiengangsanforderungen passen.',
  POPUP_INFO_STUDIENCHECK:
    'Mit dem Studiencheck können Studieninteressierte in ausgewählten Wissensbereichen prüfen,' +
    ' ob ihre Schulkenntnisse für den Wunschstudiengang ausreichen und in welchen Feldern Nachholbedarf besteht.\n' +
    'Derzeit sind Aufgaben aus den Bereichen „Mathematik“ und „Sprach- und Textverständnis“ hinterlegt.' +
    ' Aus diesen Tests wählen Vertreter/-innen eines Studiengangs die für sie relevantesten aus und stellen so einen' +
    ' auf diesen Studiengang an dieser Hochschule zugeschnittenen Studiencheck zur Verfügung.\n',
  POPUP_UEBERSCHRIFT_AUSBILDUNGSINTEGRIERENDE_STUDIENGAENGE:
    'Ausbildungsintegrierende Studiengänge',
  POPUP_INFO_AUSBILDUNGSINTEGRIERENDE_STUDIENGAENGE:
    'Studienmodell, bei dem die Studierenden parallel zum Hochschul- ' +
    'oder Berufsakademiestudium den Abschluss in einem Ausbildungsberuf erwerben.',
  POPUP_UEBERSCHRIFT_PRAXISINTEGRIERENDE_STUDIENGAENGE: 'Praxisintegrierende Studiengänge',
  POPUP_INFO_PRAXISINTEGRIERENDE_STUDIENGAENGE:
    'Studienmodell, bei dem die Studierenden Praktika in einem Unternehmen' +
    ' oder einer Institution (auf der Basis eines Praktikanten- oder Volontariatsvertrags) mit dem Studium kombinieren.',
  POPUP_UEBERSCHRIFT_BERUFSINTEGRIERENDE_STUDIENGAENGE: 'Berufsintegrierende Studiengänge',
  POPUP_INFO_BERUFSINTEGRIERENDE_STUDIENGAENGE:
    'Studienmodell, bei dem die Studierenden die (Teilzeit-)Berufstätigkeit' +
    ' in einem Unternehmen oder einer Institution mit dem Studium kombinieren und der Studiengang in Kooperation zwischen' +
    ' Betrieb und Hochschule stattfindet.',
  POPUP_UEBERSCHRIFT_BERUFSBEGLEITENDE_STUDIENGAENGE: 'Berufsbegleitende Studiengänge',
  POPUP_INFO_BERUFSBEGLEITENDE_STUDIENGAENGE:
    'Studienmodell, bei dem die Studierenden parallel zu einer beruflichen' +
    ' Vollzeittätigkeit ein berufsbegleitendes Studium absolvieren. Die Unternehmen oder Institutionen unterstützen' +
    ' dabei die Durchführung ggf. auch mit einer Förderung oder der Freistellung für Präsenzphasen und Prüfungen.\n' +
    'Das Studium findet in der Regel als Fern- oder Abendstudium statt.',
  POPUP_UEBERSCHRIFT_AUSBILDUNGSBEGLEITENDE_STUDIENGAENGE: 'Ausbildungsbegleitende Studiengänge',
  POPUP_INFO_AUSBILDUNGSBEGLEITENDE_STUDIENGAENGE:
    'Studienmodell, bei dem die Studierenden parallel zu einer beruflichen' +
    ' Ausbildung ein Studium absolvieren ohne dass zwischen beiden Bildungswegen eine fachliche Beziehung existiert.' +
    ' Das Studium findet in der Regel als Fern- oder Abendstudium statt.',
  UMKREIS_SUCHEINGABE_LABEL: 'Sucheingabe Ort',
  UMKREISAUSWAHL_LABEL: 'Auswahl Umkreis',
  UMKREISAUSWAHL_TITLE: 'Zur Änderung Suchumkreis Ortseingabe erforderlich',
  SUCHTYP_SUCHEINGABE_LABEL: 'Sucheingabe Studienangebote',
  SUCHTYPAUSWAHL_LABEL: 'Auswahl Suchtyp',
  SUCHTYP_TITLE: 'Auswahl des Types für die Sucheingabe',
  SUCHWORT_EINGABEFELD_INFO: 'Was? (z.B. Suchbegriffe oder Studienbezeichnungen...)',
  SUCHWORT_EINGABEFELD_LABEL: 'Suchbegriffe',
  ZU_VIELE_SUCHWORTE: 'Max. %max% Suchworte',
  ZU_VIELE_STUDIENFAECHER: 'Max. %max% Studienbereiche'
});
