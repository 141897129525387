import { Component } from '@angular/core';
import { FeedbackModule } from '@infosysbub/ng-lib-dpl3';

@Component({
  selector: 'ba-studisu-feedback',
  templateUrl: './feedback.component.html',
  standalone: true,
  imports: [FeedbackModule]
})
export class FeedbackComponent {}
